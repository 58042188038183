//******************************************************************
// 関数：fncInputChk()
// 機能：入力内容判定処理
// 引数：
//	[IN]	valueStr	チェック对象テキストﾎﾞｯｸｽのNAMEオブジェクト
//	[IN]	chkKind		チェック種別
//						 '0x01'：禁則文字チェック
//						 '0x02'：半角数字を許可
//						 '0x04'：半角英数字を許可
//						 '0x08'：半角カタカナを許可
//						 '0x10'：全角カタカナ、全角ひらがなを許可
//						 '0x20'：電話番号チェック
//						 '0x40'：メールアドレスチェック
//						 '0x80'：NULL許可
//	[IN]	dotChk		小数以下桁数チェックの有無（0：無 1～：有、小数桁数）
// 	[IN]	minChk		文字最小桁数チェックの有無（0：無 1～：有）
//	[IN]	maxChk		文字最大桁数チェックの有無（0：無 1～：有）
//	[OUT]	errCode		各判定ごとのエラー番号（0：判定OK 10～：エラーコード）
//						 '0x01'：禁則文字チェックエラー
//						 '0x02'：半角数字チェックエラー
//						 '0x04'：半角英数字チェックエラー
//						 '0x08'：// 半角片假名检查エラー
//						 '0x10'：全角カタカナ、全角ひらがなチェックエラー
//						 '0x20'：電話番号チェックエラー
//						 '0x40'：メールアドレスチェックエラー
//						 '0x80'：桁数チェックエラー（桁数オーバー）
//						 '0x81'：桁数チェックエラー（桁数ショート）
//						 '0xf0'：NULLチェックエラー
// 関数説明：
//		本関数は、テキストボックス等のon???イベントや、「设定」「登録」时の
//		入力文字の判定に使用します。
//		全角文字が許可の場合は、禁則文字チェックのみを指定する。
// 使用方法（コード）：
//	<input type="text" name="ID" size="9" maxlength="10" value="" onBlur="fncInputChk(this.form.ID, 0x03, 2, 0, 9);"/>
// 使用例：
//	fncInputChk(this.form.ID, 0x02, 0, 0, 16)	・・・・ 半角数値のみ。小数不可。最大桁数16桁まで。
//	fncInputChk(this.form.NUM, 0x02, 2, 1, 5)	・・・・ 半角数値のみ。小数可。小数点以下2桁まで。最大桁数5桁まで。（少数点含む）
//	fncInputChk(this.form.PASS, 0x05, 0, 8, 16)		・・・・ 半角数値,半角英字のみ。小数不可。桁数8～16桁まで。
//	fncInputChk(this.form.NAME, 0x01, 0, 0, 24)		・・・・ 全角文字可。規則文字不可。桁数24桁まで。
//	fncInputChk(this.form.email, 0x40, 0, 0, 40)	・・・・ 半角英数値のみ。'@'含む。最大桁数40桁まで。
//******************************************************************
export function fncInputChk(obj, chkKind, dotChk, minChk, maxChk)
{
	var valueStr = obj;

	// 引数チェック
	if(chkKind == null) {
		chkKind = 1;					// デフォルト判定種別
	}
	else {
		chkKind = eval(chkKind);		// 数値化変換
	}
	if(dotChk == null) dotChk = 0;		// 小数以下桁数チェック
	if(minChk == null) minChk = 0;		// 最小桁数チェック
	if(maxChk == null) maxChk = 0;		// 最大桁数チェック

	if( valueStr != "" ) {
		// 禁用字符检查
		if((chkKind&0x01)) {
			if( IsKinsoku(valueStr) == false ) {
				return 0x01;
			}
		}

		// 半角数值检查
		if((chkKind&0x02)) {
			if(IsHanDigit(valueStr, dotChk) == false) {
				return 0x02;
			}
		}

		// 半角英文数字检查
		if((chkKind&0x04)) {
			if(IsHanRoman(valueStr) == false) {
				return 0x04;
			}
		}
		// // 半角片假名检查
		if((chkKind&0x08)) {
			if(IsHanKana(valueStr) == false) {
				return 0x08;
			}
		}
		// 全角片假名，全角平假名检查
		if((chkKind&0x10)) {
			if(IsZenKana(valueStr) == false) {
				return 0x10;
			}
		}
		// 电话号码检查
		if((chkKind&0x20)) {
			if(IsTelNumber(valueStr) == false) {
				return 0x20;
			}
		}
		// 检查邮件地址
		if((chkKind&0x40)) {
			if(IsMailAddr(valueStr) == false) {
				return 0x40;
			}
		}
		// 检查MAX值
		if( maxChk != 0 ) {
			var len = getByteLength(valueStr);
			if( len > maxChk ) {
				return 0x80;
			}
			if( minChk > 0 && len < minChk ) {
				return 0x81;
			}
		}

		var getValue = obj;
		getValue = replaceAll(getValue," ","");	//半角スペースを削除
		getValue = replaceAll(getValue," ","");	//全角スペースを削除
		getValue = replaceAll(getValue,"	","");	//タブスペースを削除
		if( getValue.length <= 0 ){
			return 0x01;
		}

		return 0;
	}
	else {
		// NULL許可
		if((chkKind&0x80)) {
			return 0;
		}
		/* 何も入力されていない場合はアラート表示 */
		return 0xf0;
	}
}

// JavaScript版replaceAll
function replaceAll(expression, org, dest){
	return expression.split(org).join(dest);
}

//******************************************************************
// 関数：IsKinsoku()
// 機能：禁則文字判定
// 引数：
//	 [IN]	str			チェック对象の文字列
//******************************************************************
export function IsKinsoku(str) {
	if( str.match(/[!#$%&"\\`~^\/:*?<>|;\,']/g) ) {
		return false;
	}
	else {
		return true;
	}
}

//******************************************************************
// 関数：IsHanDigit()
// 機能：半角数字判定
// 引数：
//	 [IN]	str			チェック对象の文字列
//	 [IN]	dotketa		小数桁数
//******************************************************************
function IsHanDigit(str, dotketa) {
	var length = 0;		// 小数点以下の桁数

	if( dotketa != 0 ){
		if( str.match(/[^0-9\-.]/g) ) {
			// パターンマッチ 0～9,"-","."以外はNG
			return false;
		}
	}else{
		if( str.match(/[^0-9\-]/g) ) {
			// パターンマッチ 0～9,"-"以外はNG
			return false;
		}
	}
	if( str.match(/[^0-9|^\-|^.]/g) ) {
		// パターンマッチ 0～9,"-","."以外はNG
		return false;
	}
	if( str.match(/[^\-|^.]/g) ) {
	}
	else {
		// パターンマッチ "-","."のみはNG
		return false;
	}
	// 小数点の数を取得する
	var count = 0;
	for( var i = 0; i < str.length; i++) {
		if (str.charAt(i) == ".") {
			count++;
		}
	}
	if( dotketa != 0 && 1 < count ) {
		// "."が2つ以上入力されている場合はNG
		return false;
	}
	if( str.charAt(0) == "." ) {
		// 先頭に小数点が入力された場合はNG
		return false;
	}
	if( str.charAt((str.length-1)) == "." ) {
		// 最後に小数点が入力された場合はNG
		return false;
	}
	// 小数点以下のチェック
	if( count == 1 ) {
		// 小数点が入力された場合のみチェック
		// 小数点以下の桁数チェック
		var idx = str.lastIndexOf(".");
		var decimalPart = str.substring(idx);
		// 小数点以下の桁数を取得する
		length = decimalPart.length - 1;
		if( dotketa < length ) {
			// 小数点以下の桁数がオーバーしている場合はNG
			return false;
		}
	}

	// "-"の入力個数を取得する
	count = 0;
	for( i = 0; i < str.length; i++ ) {
		if( str.charAt(i) == "-" ) {
			count++;
		}
	}
	if( 2 <= count || (count == 1 && str.charAt(0) != "-")) {
		// "-"が2つ以上入力されている場合はNG
		// "-"が入力されていて、かつ先頭に"-"がない場合はNG
		return false;
	}

	return true;
}

//******************************************************************
// 関数：IsHanRoman()
// 機能：半角英数字判定
// 引数：
//	 [IN]	str			チェック对象の文字列
//******************************************************************
function IsHanRoman(str) {
	if( str.match(/[^a-zA-Z0-9]/g)) {
		return false;
	}
	else {
		return true;
	}
}
//******************************************************************
//関数：IsHanKana()
//機能：半角カタカナ判定
//变量：
//	 [IN]	str			チェック对象の文字列
//******************************************************************
function IsHanKana(str) {
	if( str.match(/[^ｱ-ﾝｰﾞﾟ]/g)) {
		return false;
	}
	else {
		return true;
	}
}

//******************************************************************
// 関数：IsZenKana()
// 機能：全角かな・カナ文字・全角数値判定
// 引数：
//	 [IN]	str			チェック对象の文字列
//******************************************************************
function IsZenKana(str) {
	// 文字コードでのチェックに変更？
	if( str.match(/[^ァ-ロワヲンヴ－ーぁ-ん0-9]/g)) {
		// 条件以外のものが含んでいるためNG
		return false;
	}
	else {
		return true;
	}
}

//******************************************************************
// 関数：IsTelNumber()
// 機能：電話番号判定
//		※9999-9999-9999の形式と一致するかどうかで判定。
// 引数：
//	 [IN]	str			チェック对象の文字列
//******************************************************************
function IsTelNumber(str)
{
	//  /[0-9]+\-[0-9]+\-[0-9]+$/
	if( str.match(/^0\d{2,3}-?\d{7,8}$/)) {
		return true;
	} else {
		return false;
	}
}

//******************************************************************
//関数：IsZipCode()
//機能：郵便番号判定
//		※999-9999の形式と一致するかどうかで判定。
//变量：
//	 [IN]	str			チェック对象の文字列
//******************************************************************
function IsZipCode(str)
{
	if( str.match(/[0-9]+\-[0-9]+$/)) {
		return true;
	}
	else {
		return false;
	}
}

//******************************************************************
//関数：IsMailAddr()
//機能：メールアドレス判定
//		※???@???.??の形式と一致するかどうかで判定。
//变量：
//	 [IN]	str			チェック对象の文字列
//******************************************************************
function IsMailAddr(str)
{
	/* すべて半角文字か?	*/
	if( isAllHalfSize(str) == false ) {
		return false;
	}
	/* '@'が2回以上登場する?	*/
	var count = 0;
	for( var i = 0; i < str.length; i++) {
		// スペース入力規制
		if ( str.charAt(i) == " "){
			return false;
		}

		if (str.charAt(i) == "@") {
			count++;
		}
	}
	if( count == 0 || count > 1 ) {
		return false;
	}

	/* ドメイン名称チェック */
	// @が含まれている。
	if( str.match(/[!#-9A-~]+@+[a-z0-9]/)) {
		// 末尾.はNG
		if(str.charAt(str.length-1) == "."){
			return false;
		}
		/* ドメインの2文字目以降'-''.'が登場する？	*/
		if( str.match(/[!#-9A-~]+@+[a-z0-9]+[\-\.]{2}/) ) {
			return false;
		}
		return true;
	}
	else {
		return false;
	}
}
//******************************************************************
//関数：isAllHalfSize()
//機能：チェック对象文字列が半角のみか判定
//变量：
//	 [IN]	str			チェック对象の文字列
//	 [OUT]	return		全て半角文字だった場合はtrue
//						半角文字以外が含まれている場合はfalse
//******************************************************************
function isAllHalfSize(str)
{
	var length = str.length;
	if( length == getByteLength(str)) {
		return true;
	}
	else {
		return false;
	}
}

//******************************************************************
// 関数：getByteLength()
// 機能：文字列ﾊﾞｲﾄ数判定
// 引数：
//	 [IN]	str			チェック对象の文字列
//	 [OUT]	return		文字列のバイト数
//******************************************************************
var HALF_SIZE_KANA = "ｧｱｨｲｩｳｪｴｫｵｶｷｸｹｺｻｼｽｾｿﾀﾁｯﾂﾃﾄﾅﾆﾇﾈﾉﾊﾋﾌﾍﾎﾏﾐﾑﾒﾓｬﾔｭﾕｮﾖﾗﾘﾙﾚﾛﾜｦﾝｰﾟﾞ";
function getByteLength(str)
{
	var i = 0;
	var count = 0;
	for( i = 0; i < str.length; i++ ) {
		// JS内文字コード(UTF-8)とWindows標準(ShiftJIS)がバイト数が異なるため、
		// ShiftJISで換算したサイズを返却する

		// ShiftJIS: 0x0 - 0x80 , 0xa0   , 0xa1   - 0xdf   , 0xfe   - 0xff
		// UTF-8   : 0x0 - 0x80 , 0xf8f0 , 0xff61 - 0xff9f , 0xf8f1 - 0xf8f3
		var code = str.charCodeAt(i);
		if(	(code >= 0x0 && code <= 0x80) ||
			(code == 0xf8f0) ||
			(code >= 0xff61 && code <= 0xff9f) ||
			(code >= 0xf8f1 && code <= 0xf8f3)){
			count += 1;
		}else{
			count += 2;
		}

	}
	return count;
}